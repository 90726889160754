import { useEffect, useRef, useState } from "react"
import { FaSearch } from "react-icons/fa"

interface SearchbarProps {
  handleSearch: (query: string) => void
  className?: string
}

export default function Searchbar({ handleSearch, className }: SearchbarProps) {
  const [value, setValue] = useState("")
  const [open, setOpen] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleChange = (e: { target: { value: string } }) => {
    setValue(e.target.value)
  }

  const handleKeyDown = (e: { key: string }) => {
    if (e.key === "Enter") {
      handleSearch(value)
    }
  }

  const handleBlur = (e: { relatedTarget: (EventTarget & Element) | null }) => {
    setOpen(false)
    if (e.relatedTarget === null) {
      setOpen(false)
      handleSearch(value)
    }
    return
  }

  return (
    <div className="flex">
      {!open ? (
        <FaSearch className="text-gray-600 self-center" onClick={() => setOpen(true)} />
      ) : (
        <div onBlur={handleBlur} className="flex">
          <input
            className={"sm:text-sm rounded-md appearance-none relative block px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:bg-secondary-extra-light focus:border-purple-500 focus:z-10 focus:w-64" + " " + className}
            value={value}
            autoFocus
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder="Search"
            ref={inputRef}
          />
          {/* Add a clear button or x */}
          <button
            onMouseDown={(e) => {
              e.preventDefault()
              setValue("")
              
              setTimeout(() => {
                // allows the input to be cleared and the onBlur to finish before focusing on the input again
                inputRef.current?.focus();

              }, 0);
            }}
            className="text-gray-600 self-center p-2"
          >
            x
          </button>
        </div>
      )}
    </div>
  )
}
