import { useLocation, useNavigate, useParams } from "react-router-dom"
import { ExtendedWalkReport, MarkerImage, MarkerLocation, WalkNote } from "../../../../Interfaces"
import Map from "../../../../components/maps/Map"
import { useContext, useEffect, useRef, useState } from "react"
import DataContext from "../../../../context/dataProvider"
import { useQuery } from "@tanstack/react-query"
import { locationService } from "../../../../services/locationService"
import PopupModal from "../../../../components/modals/PopupModal"
import { walkService } from "../../../../services/walkService"
import { getTimeDifference } from "../../../../utils/helper"
// import testImage from "../../../../assets/test-image.jpg"

type Props = {}
export default function SingleWalkReport({}: Props) {
  const location = useLocation()
  const navigate = useNavigate()
  const { id, walkReportId } = useParams()
  const { data: walkReport } = useQuery(["walkReport", walkReportId], () => walkService.getOneReport(Number(walkReportId)), {
    enabled: walkReportId !== undefined,
  }) 


  const [modalContent, setModalContent] = useState<any>()
  const [isOpen, setIsOpen] = useState(false)
  const { currentLocation } = useContext(DataContext)
  const [markerImages, setMarkerImages] = useState<MarkerImage[]>(walkReport?.markerImage || [])
  const [markers, setMarkers] = useState<MarkerLocation[]>(walkReport?.walk.markers || [])
  const isCurrentLocationEmpty = currentLocation && currentLocation.id !== walkReport?.walk.locationId
  // This is only used if the page is refreshed and currentLocation is empty
  const { data, isLoading } = useQuery(["currentLocation", walkReport?.walk.locationId!], () => locationService.getOne(walkReport?.walk.locationId!), { enabled: isCurrentLocationEmpty })
  const locationData = isCurrentLocationEmpty ? data : currentLocation
  const coordinates = useRef<google.maps.LatLngLiteral>({ lat: Number(locationData.latitude), lng: Number(locationData.longitude) })
  const [zoom, setZoom] = useState<number>(locationData.zoomLevel || 18)

  useEffect(() => {
    // use walkService to get presigned urls for marker images while page loads. Assign them to the markerImage.url property
    if (!walkReport?.markerImage) return
      const markerImages = walkReport.markerImage.map(async (image) => {
      const presignedViewableUrl = await walkService.getViewableMarkerImage(image.walkReportId, image.markerId, image.imageName)
      image.imageUrl = presignedViewableUrl
      return image
    })
    Promise.all(markerImages).then((images) => {
      setMarkerImages(images)
    })
  }, [walkReport?.markerImage])
  


  useEffect(() => {
    if (!currentLocation) return
    const walkNotes = walkReport?.walkNote
    const mergedMarkers: MarkerLocation[] = markers?.map((marker) => {
      const notes = walkNotes?.filter((note) => note.markerId === marker.id)
      const images = markerImages?.filter((image) => image.markerId === marker.id)
      return {
        ...marker,
        notes,
        images,
      }
    })
      .sort((a, b) => a.label - b.label) || [];
    setMarkers(mergedMarkers);
    setZoom(locationData.zoomLevel || 18);
  }, [data])

  if( !walkReport || !walkReport.walk || !walkReport.walk.locationId) return null
  
  const timeDifference = walkReport?.finishedAt ? getTimeDifference(new Date(walkReport?.startedAt), new Date(walkReport?.finishedAt)) : { hours: 0, minutes: 0, seconds: 0 }

  const totalWalkTime = Number(timeDifference.hours) + Number(timeDifference.minutes) + Number(timeDifference.seconds) > 0 && `${timeDifference.hours + ":" + timeDifference.minutes + ":" + timeDifference.seconds}`

  const finishedAt = walkReport?.finishedAt ? new Date(walkReport?.finishedAt).toLocaleTimeString() : "Not Completed"

  function handleNoteClick(notes: WalkNote[]) {
    if (!notes) return
    setModalContent(
      <div className="flex flex-wrap sm:rounded-lg p-1 bg-secondary-extra-light max-w-3xl text-lg">
        <div className="w-full flex flex-col overflow-auto max-h-[800px]">
          {notes.map((note: WalkNote) => (
            <ul key={note.id}>
              <li className="p-2 rounded-md shadow-md">{note.note}</li>
            </ul>
          ))}
        </div>
      </div>
    )
    setIsOpen(true)
  }

  function handleImageClick(images: MarkerImage[]) {
    if (!images) return

    setModalContent(
      <div className="flex flex-col overflow-auto max-h-[750px]">
        {images.map((image: MarkerImage) => (
          // contain images and make them scrollable if they are too big or there are too many
          <div className="object-contain h-full max-w-5xl" key={image.id}>
            {image.imageUrl ? (
              <img src={image.imageUrl} alt={image.imageName} />
            ) : (
              <div className=" h-72 w-full min-w-[400px] bg-slate-300 flex justify-center items-center px-4">
                <h2 className="font-bold text-center">No Image Available</h2>
              </div>
            )}
          </div>
        ))}
      </div>
    )
    setIsOpen(true)
  }

  function handleCloseModal() {
    setIsOpen(false)
    setModalContent(null)
  }

  return (
    <div className="w-full min-h-full flex justify-center">
      <div className="w-full flex flex-col">
        <section className="bg-secondary-extra-light p-2 md:rounded-lg mb-2 shadow-lg h-full">
          <div className="bg-primary-dark h-full flex flex-col">
            <div className=" text-white rounded-lg p-4 flex justify-between">
              <div className="flex">
                <div className="mr-6">
                  <h3 className=" font-extrabold">{walkReport.user.firstName + " " + walkReport.user.lastName}</h3>
                  <h4 className="">{walkReport.walk.name}</h4>
                  <h4 className="">Date: {new Date(walkReport.finishedAt || walkReport.createdAt).toLocaleDateString()}</h4>
                </div>
                <div>
                  <h3 className="">{" "}</h3>
                  <h4 className=" pr-2">Started: {new Date(walkReport.startedAt).toLocaleTimeString()}</h4>
                  <h4 className="">Finished: {finishedAt}</h4>
                  <h4 className="">
                    Total Time: {totalWalkTime || "Not Completed"}
                  </h4>
                </div>
              </div>

              <div>
                <div className="flex justify-end p-2">
                  <button className="rounded-lg p-2 ml-3 text-white text-center bg-primary border-sedondary hover:border-2 hover:border-primary-light" onClick={() => navigate(`/locations/${id}`)}>
                    Walk Reports
                  </button>
                </div>
              </div>
            </div>

            <div className="flex flex-grow">
              <div className="shadow-lg  bg-secondary-extra-light w-full flex-grow relative">
                <Map name={""} zoom={zoom} setZoom={setZoom} markers={markers} coordinates={coordinates.current} walkNotes={walkReport.walkNote} walkImages={walkReport.markerImage} handleNoteClick={handleNoteClick} handleImageClick={handleImageClick} animate={true}/>
              </div>
            </div>
          </div>
        </section>
      </div>
      <PopupModal isOpen={isOpen} onCancel={handleCloseModal} className={``}>
        {modalContent}
      </PopupModal>
    </div>
  )
}
