import { useQuery } from "@tanstack/react-query"
import { locationService } from "../../services/locationService"
import { useContext, useEffect, useState } from "react"
import UiContext from "../../context/uiProvider"
import { DESKTOP, TABLET } from "../../utils/screenSize"
import useUser from "../../hooks/useUser"
import { Location } from "../../Interfaces"
import Table from "./common/Table"

export default function LocationsTable({ userProfileId = undefined }: { userProfileId?: number }) {
  const { id } = useUser()
  const { deviceView } = useContext(UiContext)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(10) // can be changed to a user setting later
  const [totalLocations, setTotalLocations] = useState(0)
  const [searchTerm, setSearchTerm] = useState<string>("")
  const userId = userProfileId || id

  const { data, isLoading } = useQuery<{ locations: Location[]; totalCount: number }>(["locations", "userId" + userId, currentPage, limit, searchTerm], () => locationService.getSomeByUser(userId, limit, currentPage - 1, searchTerm), { cacheTime: 5000, suspense: true })

  const [filteredData, setFilteredData] = useState<Location[] | undefined | null>(null)

  useEffect(() => {
    if (data) {
      setFilteredData(data.locations)
      setTotalLocations(data.totalCount)
    }
  }, [data])

  useEffect(() => {
    setCurrentPage(1)
  }, [limit])

  const headers: {label: string, key: string, to?: string}[] = [
    { label: "Id", key: "id", to: `/locations`},
    { label: "Name", key: "name", to: `/locations`},
  ]

  // Add two more columns on tablets and desktops
  if (deviceView >= TABLET) {
    headers.push({ label: "Address", key: "address" }, { label: "City", key: "city" }, { label: "State", key: "state" }, { label: "Zipcode", key: "zipcode" })
  }
  // Add two additional columns on desktops
  if (deviceView >= DESKTOP) {
    // headers.push({ label: "Latitude", key: "latitude" }, { label: "Longitude", key: "longitude" })
  }

  function handleSearch(query: string) {
    setCurrentPage(1)
    setSearchTerm(query)
  }

  if (!filteredData || isLoading) return null

  return (
    <Table data={filteredData} title={"Locations"} to={"/addLocation"} currentPage={currentPage} setCurrentPage={setCurrentPage} limit={limit} setLimit={setLimit} handleSearch={handleSearch} headers={headers} totalCount={totalLocations} setTotalCount={setTotalLocations} />
  )
}
