export const loginFields = [
  {
    labelText: "Email address",
    labelFor: "email",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Email address",
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "current-password",
    isRequired: true,
    placeholder: "Password",
  },
]

export const resetPasswordFields = [
  {
    labelText: "Email address",
    labelFor: "email",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Email address",
  },
]

export const confirmPasswordFields = [
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "current-password",
    minLength: 8,
    isRequired: true,
    placeholder: "Password",
  },
  {
    labelText: "Confirm Password",
    labelFor: "confirmPassword",
    id: "confirmPassword",
    name: "confirmPassword",
    type: "password",
    autoComplete: "confirmPassword",
    minLength: 8,
    isRequired: true,
    placeholder: "Confirm Password",
  },
]

export const signupFields = [
  {
    labelText: "Username",
    labelFor: "username",
    id: "username",
    name: "username",
    type: "text",
    autoComplete: "username",
    isRequired: true,
    placeholder: "Username",
  },
  {
    labelText: "Email address",
    labelFor: "email",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Email address",
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "current-password",
    isRequired: true,
    minLength: 8,
    placeholder: "Password",
  },
  {
    labelText: "Confirm Password",
    labelFor: "confirmPassword",
    id: "confirmPassword",
    name: "confirmPassword",
    type: "password",
    autoComplete: "confirmPassword",
    isRequired: true,
    minLength: 8,
    placeholder: "Confirm Password",
  },
]

export const addManagementCompanyFields = [
  {
    labelText: "Company Name",
    labelFor: "companyName",
    id: "companyName",  
    name: "name",
    type: "text",
    autoComplete: "companyName",
    isRequired: true,
    placeholder: "Company Name",
  },
  {
    labelText: "Email address",
    labelFor: "email",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Email address",
  },
  {
    labelText: "Phone Number",
    labelFor: "phone",
    id: "phone",
    name: "phone",
    type: "tel",
    autoComplete: "phone",
    isRequired: false,
    placeholder: "Phone Number -> 555-555-5555",
    pattern:"^\\d{3}-\\d{3}-\\d{4}$",
    maxLength: 12,
  },
  {
    labelText: "Website",
    labelFor: "website",
    id: "website",
    name: "website",
    type: "url",
    autoComplete: "website",
    isRequired: false,
    placeholder: "Website",
  }
]

export const addUserFields = [
  {
    labelText: "First Name",
    labelFor: "firstName",
    id: "firstName",
    name: "firstName",
    type: "text",
    autoComplete: "firstName",
    isRequired: true,
    placeholder: "First Name",
  },
  {
    labelText: "Last Name",
    labelFor: "lastName",
    id: "lastName",
    name: "lastName",
    type: "text",
    autoComplete: "lastName",
    isRequired: true,
    placeholder: "Last Name",
  },
  {
    labelText: "Email address",
    labelFor: "email",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Email Address",
  },
  {
    labelText: "Phone Number",
    labelFor: "phone",
    id: "phone",
    name: "phone",
    type: "text",
    autoComplete: "phone",
    isRequired: true,
    placeholder: "Phone Number -> 555-555-5555",
    pattern:"^\\d{3}-\\d{3}-\\d{4}$",
    maxLength: 12,
  },
  // {
  //   labelText: "6 Digit Pin",
  //   labelFor: "pin",
  //   id: "pin",
  //   name: "pin",
  //   type: "text",
  //   autoComplete: "pin",
  //   isRequired: true,
  //   placeholder: "6 Digit Pin",
  //   pattern:"^[0-9]*$",
  //   maxLength: 6,
  //   minLength: 6,
  // },
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "new-password",
    minLength: 8,
    isRequired: true,
    placeholder: "Password",
  },

  {
    labelText: "Confirm Password",
    labelFor: "confirmPassword",
    id: "confirmPassword",
    name: "confirmPassword",
    type: "password",
    autoComplete: "new-password",
    minLength: 8,
    isRequired: true,
    placeholder: "Confirm Password",

  },
]

export const addOrganizationFields = [
  {
    labelText: "Area Name",
    labelFor: "AreaName",
    id: "areaName",  
    name: "name",
    type: "text",
    isRequired: true,
    placeholder: "Area Name",
  },
  // {
  //   labelText: "Address",
  //   labelFor: "address",
  //   id: "address",
  //   name: "address",
  //   type: "text",
  //   autoComplete: "address",
  //   isRequired: true,
  //   placeholder: "Address",
  // },
  // {
  //   labelText: "City",
  //   labelFor: "city",
  //   id: "city",
  //   name: "city",
  //   type: "text",
  //   autoComplete: "city",
  //   isRequired: false,
  //   placeholder: "City",
  // },
  // {
  //   labelText: "State",
  //   labelFor: "state",
  //   id: "state",
  //   name: "state",
  //   type: "text",
  //   autoComplete: "state",
  //   isRequired: false,
  //   placeholder: "State",
  // },
  // {
  //   labelText: "Zipcode",
  //   labelFor: "zipcode",
  //   id: "zipcode",
  //   name: "zipcode",
  //   type: "number",
  //   isRequired: false,
  //   placeholder: "5 digit zipcode",
  // },
  // {
  //   labelText: "Email address",
  //   labelFor: "email",
  //   id: "email",
  //   name: "email",
  //   type: "email",
  //   autoComplete: "email",
  //   isRequired: true,
  //   placeholder: "Email Address",
  // },
  // {
  //   labelText: "Phone Number",
  //   labelFor: "phone",
  //   id: "phone",
  //   name: "phone",
  //   type: "tel",
  //   autoComplete: "phone",
  //   isRequired: false,
  //   placeholder: "Phone Number -> 555-555-5555",
  //   pattern:"^\\d{3}-\\d{3}-\\d{4}$",
  //   maxLength: 12,
  // },
  // {
  //   labelText: "Website",
  //   labelFor: "website",
  //   id: "website",
  //   name: "website",
  //   type: "url",
  //   autoComplete: "website",
  //   isRequired: false,
  //   placeholder: "Website",
  // }
]

export const addLocationFields = [
  {
    labelText: "Location Name",
    labelFor: "locationName",
    id: "locationName",  
    name: "name",
    type: "text",
    autoComplete: "locationName",
    isRequired: true,
    placeholder: "Location Name",
    disabled: false,
  },
  {
    labelText: "Address",
    labelFor: "address",
    id: "address",
    name: "address",
    type: "text",
    autoComplete: "address",
    isRequired: true,
    placeholder: "Address",
    
  },
  {
    labelText: "City",
    labelFor: "city",
    id: "city",
    name: "city",
    type: "text",
    autoComplete: "city",
    isRequired: false,
    placeholder: "City",
    disabled: false,
  },
  {
    labelText: "State",
    labelFor: "state",
    id: "state",
    name: "state",
    type: "text",
    autoComplete: "state",
    isRequired: false,
    placeholder: "State",
    disabled: false,
  },
  {
    labelText: "Zipcode",
    labelFor: "zipcode",
    id: "zipcode",
    name: "zipcode",
    type: "number",
    isRequired: false,
    placeholder: "5 digit zipcode",
    disabled: false,
  },
]
