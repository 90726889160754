import { useEffect, useState } from "react"
import { InputProps } from "../../Interfaces"
import useDevice from "../../hooks/useDevice"
import { SMALL_TABLET } from "../../utils/screenSize"

// const fixedInputClass = "rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm "


export default function DynamicInput({ onChange, value, labelText, labelFor, id, name, type, isRequired = false, placeholder, className, pattern, disabled, minLength, maxLength, autoComplete }: InputProps) {
  const deviceView = useDevice()
  const [dynamicWidth, setDynamicWidth] = useState(0)

  useEffect(() => {
    setDynamicWidth((prev) => 0)
    for (let i = 0; i < value.length; i++) {
      // get width of each character
      setDynamicWidth((prev) => prev + 11)
      if(value[i] === "W" || value[i] === "M" || value[i] === "w" || value[i] === "m" || value[i] === "A" || value[i] === "B") {
        setDynamicWidth((prev) => prev + 2.5)
      } else if (value[i] === "i" || value[i] === "I" || value[i] === "l" || value[i] === "1" || value[i] === " ") {
        setDynamicWidth((prev) => prev - 2)
      }
    }
    console.log(dynamicWidth)
  }, [value])
  

  return (
 
      <input onChange={onChange} value={value} id={id} name={name} type={type} required={isRequired} className={className="m-2 rounded-md bg-secondary-extra-light text-black px-2 min-w-48" || className} placeholder={placeholder} pattern={pattern} disabled={disabled} maxLength={maxLength} minLength={minLength} autoComplete={autoComplete} style={{ width: (dynamicWidth) + "px", maxWidth: deviceView < SMALL_TABLET ? 200 : 290, minWidth:100}}/>

  )
}
