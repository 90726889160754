import { useState } from "react"
import { FieldState, Location, ManagementCompany, NewEmployee, Organization, User } from "../../Interfaces"
import useUser from "../../hooks/useUser"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { locationService } from "../../services/locationService"
import { ADMIN, AREA_MANAGER, ASSISTANT_MANAGER, COMPANY_MANAGER, MANAGER, OWNER } from "../../utils/roles"
import { cleanPhoneNumber, doPasswordsMatch, phoneNumberAutoFormat } from "../../utils/helper"
import CustomSelect from "./CustomSelect"
import Input from "./Input"
import ImageDragDrop from "./ImageDragDrop"
import { organizationService } from "../../services/organizationService"
import { managementCompanyService } from "../../services/managementCompanyService"
import { userService } from "../../services/userService"
import { MultiValue, SingleValue } from "react-select"
import { useNavigate } from "react-router-dom"
import SaveButtonWithStatus from "../general/SaveButtonWithStatus"
import { useErrorBoundary } from "react-error-boundary"
import validateInput from "../../utils/validate"

export default function AddUserForm({ fieldType, newEmployeeRole }: { fieldType: any[]; newEmployeeRole: number }) {
  const fields = fieldType
  let fieldsState: FieldState = {}
  // Set each input field to an empty string initially
  fields.forEach((field) => (fieldsState[field.name] = ""))
  const { id } = useUser()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { showBoundary } = useErrorBoundary()

  // By using "enabled" we can control when the query is executed. It is used to prevent the query from running until the user has selected a role
  const { data: locations } = useQuery(["locations"], () => locationService.getAllByUser(id), { enabled: newEmployeeRole === MANAGER || newEmployeeRole === ASSISTANT_MANAGER })
  const { data: organizationList } = useQuery(["organizations"], () => organizationService.getAllByUser(id), { enabled: newEmployeeRole === AREA_MANAGER })
  const { data: managementCompanyList } = useQuery(["managementCompanies"], () => managementCompanyService.getAll(id), { enabled: newEmployeeRole === COMPANY_MANAGER || newEmployeeRole === OWNER })

  const [addEmployeeStateForm, setAddEmployeeStateForm] = useState(fieldsState)
  const [imageFile, setImageFile] = useState<File | null>(null)
  const [selectedLocationIds, setSelectedLocationsIds] = useState<number[]>([])
  const [selectecOrganizationIds, setSelectedOrganizationIds] = useState<number[]>([])
  const [selectedManagementCompanyId, setSelectedManagementCompanyId] = useState<number>(0)
  const [saveStatus, setSaveStatus] = useState<string | null>(null)

  const handleInputChange = (e: {
    target: {
      validity: any
      setCustomValidity(arg0: string): unknown
      name: string
      value: string
    }
  }) => {
    const input = e.target
    if (input.name === "phone") {
      const phoneNumber = phoneNumberAutoFormat(input.value)
      validateInput(input, "Please match the pattern: 555-555-5555.")
      setAddEmployeeStateForm({ ...addEmployeeStateForm, [input.name]: phoneNumber })
    } else {
      setAddEmployeeStateForm({ ...addEmployeeStateForm, [input.name]: input.value })
    }
  }

  const handleLocationSelection = (locations: MultiValue<Location>) => {
    const locationIds = locations.map((location): number => location.id!!)
    setSelectedLocationsIds(locationIds)
  }

  const handleOrganizationSelect = (organizations: MultiValue<Organization>) => {
    const organizationIds = organizations.map((organization): number => organization.id!!)
    setSelectedOrganizationIds(organizationIds)
  }

  const handleManagementCompanySelect = (managementCompany: SingleValue<ManagementCompany>) => {
    if (managementCompany) {
      setSelectedManagementCompanyId(managementCompany.id)
    }
  }

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    const isValid = isFormValid(addEmployeeStateForm)
    const newEmployee: NewEmployee = { ...addEmployeeStateForm, phone: cleanPhoneNumber(addEmployeeStateForm.phone) }
    queryClient.invalidateQueries(["users"])
    let user: User | null = null
    if (isValid) {
      setSaveStatus("Saving...")
      if (newEmployeeRole === ASSISTANT_MANAGER) {
        newEmployee.password = newEmployee.password?.trim()
        delete newEmployee.confirmPassword
        try {
          user = await userService.createAssistantManager(newEmployee, selectedLocationIds, imageFile)
        } catch (error: any) {
          if (error.message === "Conflict") {
            setSaveStatus("Error")
            alert("An email has already been registered with this account. Please check the email and try again.")
          } else {
            showBoundary(error)
          }
        }
      }
      if (newEmployeeRole === MANAGER) {
        newEmployee.password = newEmployee.password?.trim()
        delete newEmployee.confirmPassword
        try {
          user = await userService.createManager(newEmployee, selectedLocationIds, imageFile)
        } catch (error: any) {
          if (error.message === "Conflict") {
            setSaveStatus("Error")
            alert("An email has already been registered with this account. Please check the email and try again.")
          } else {
            showBoundary(error)
          }
        }
      } else if (newEmployeeRole === AREA_MANAGER) {
        newEmployee.password = newEmployee.password?.trim()
        delete newEmployee.confirmPassword
        try {
          user = await userService.createAreaManager(newEmployee, selectecOrganizationIds, imageFile)
        } catch (error: any) {
          if (error.message === "Conflict") {
            setSaveStatus("Error")
            alert("An email has already been registered with this account. Please check the email and try again.")
          } else {
            showBoundary(error)
          }
        }
      } else if (newEmployeeRole === COMPANY_MANAGER) {
        // Should always be Manager
        newEmployee.password = newEmployee.password?.trim()
        delete newEmployee.confirmPassword
        try {
          user = await userService.createCompanyManager(newEmployee, selectedManagementCompanyId, imageFile)
        } catch (error: any) {
          if (error.message === "Conflict") {
            setSaveStatus("Error")
            alert("An email has already been registered with this account. Please check the email and try again.")
          } else {
            showBoundary(error)
          }
        }
      } else if (newEmployeeRole === OWNER) {
        // Should always be Manager
        newEmployee.password = newEmployee.password?.trim()
        delete newEmployee.confirmPassword
        try {
          user = await userService.createOwner(newEmployee, selectedManagementCompanyId, imageFile)
        } catch (error: any) {
          if (error.message === "Conflict") {
            setSaveStatus("Error")
            alert("An email has already been registered with this account. Please check the email and try again.")
          } else {
            showBoundary(error)
          }
        }
      } else if (newEmployeeRole === ADMIN) {
        // Should always be Manager
        newEmployee.password = newEmployee.password?.trim()
        delete newEmployee.confirmPassword
        try {
          user = await userService.createAdmin(newEmployee, imageFile)
        } catch (error: any) {
          if (error.message === "Conflict") {
            setSaveStatus("Error")
            alert("An email has already been registered with this account. Please check the email and try again.")
          } else {
            showBoundary(error)
          }
        }
      }

      if (user) {
        setSaveStatus("Saved!")
        setTimeout(() => {
          setSaveStatus(null)
          if (user) navigate(`/users/${user.id}`, { state: { user: user } })
        }, 1000)
      } else {
        setSaveStatus("Error")
        setTimeout(() => {
          setSaveStatus(null)
        }, 1500)
      }
    }
  }

  const isFormValid = (addEmployeeStateForm: FieldState) => {
    const passwordMatch = doPasswordsMatch(addEmployeeStateForm.password, addEmployeeStateForm.confirmPassword)
    if (!passwordMatch) {
      alert("Passwords do not match")
      return false
    }
    if (newEmployeeRole > MANAGER && newEmployeeRole < ADMIN) {
      const ManagementOrOrgsSelected = selectedManagementCompanyId !== 0 || selectecOrganizationIds?.length > 0
      if (!ManagementOrOrgsSelected) {
        alert("Did you choose a management company or organization?")
        return false
      }
    } else if (newEmployeeRole === MANAGER || newEmployeeRole === ASSISTANT_MANAGER) {
      if (selectedLocationIds?.length === 0) {
        alert("Did you choose at least one location?")
        return false
      }
    }
    return true
  }

  return (
    <form className="mt-6 space-y-2" onSubmit={handleSubmit}>
      <div className="lg:flex lg:justify-evenly items-center">
        <section className="lg:w-full">
          <div>
            {newEmployeeRole === OWNER && (
              <>
                {!managementCompanyList?.length ? (
                  <p className="text-red-500">Please add a management company first</p>
                ) : (
                  <div className="">
                    <CustomSelect list={managementCompanyList} handleSelection={handleManagementCompanySelect} placeholder="Select Management Company" required={true} />
                  </div>
                )}
              </>
            )}
            {newEmployeeRole === COMPANY_MANAGER && (
              <>
                {!managementCompanyList?.length ? (
                  <p className="text-red-500">Please add a management company first</p>
                ) : (
                  <div className="">
                    <CustomSelect list={managementCompanyList} handleSelection={handleManagementCompanySelect} placeholder="Select Management Company" required={true} />
                  </div>
                )}
              </>
            )}
            {newEmployeeRole === AREA_MANAGER && (
              <>
                {!organizationList?.length ? (
                  <p className="text-red-500">Please add an area first</p>
                ) : (
                  <div>
                    <CustomSelect list={organizationList ? organizationList : []} handleSelection={handleOrganizationSelect} isMulti={true} placeholder="Select Area" required={true} />
                  </div>
                )}
              </>
            )}
            {newEmployeeRole === MANAGER && (
              <>
                {!locations?.length ? (
                  <p className="text-red-500">Please add a location first</p>
                ) : (
                  <div className="p2">
                    <CustomSelect list={locations ? locations : []} handleSelection={handleLocationSelection} isMulti={true} placeholder="Select Locations ( primary first )" required={true} />
                  </div>
                )}
              </>
            )}
            {newEmployeeRole === ASSISTANT_MANAGER && (
              <>
                {!locations?.length ? (
                  <p className="text-red-500">Please add a location first</p>
                ) : (
                  <div className="p2">
                    <CustomSelect list={locations ? locations : []} handleSelection={handleLocationSelection} isMulti={true} placeholder="Select Locations ( primary first )" required={true} />
                  </div>
                )}
              </>
            )}
            {fields?.map((field) => {
              return (
                <Input
                  className="bg-gray-50 border-gray-300"
                  key={field.id}
                  handleChange={handleInputChange}
                  value={addEmployeeStateForm[field.id]}
                  labelText={field.labelText}
                  labelFor={field.labelFor}
                  id={field.id}
                  name={field.name}
                  type={field.type}
                  autoComplete={field.autoComplete}
                  isRequired={field.isRequired}
                  placeholder={field.placeholder}
                  pattern={field.pattern}
                  minLength={field.minLength}
                  maxLength={field.maxLength}
                />
              )
            })}
          </div>
          <div className="w-full"></div>
        </section>
        <section className="lg:w-full lg:pl-5 lg:m-auto">
          <ImageDragDrop setImageState={setImageFile} title="Upload Photo" />
        </section>
      </div>
      <SaveButtonWithStatus saveStatus={saveStatus} handleSave={handleSubmit} isForm={true} className="w-full" />
    </form>
  )
}
